import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const BlogPage = ({ data }) => {
  const nodes = data.allMdx.edges // data.markdownRemark holds your post data
  return <Layout>
    {nodes.map(({ node }, index) => (
      <div key={ index }>
        <a href={'blog/' + node.frontmatter.slug + '/'}>{node.frontmatter.title}</a>
      </div>
    ))}
  </Layout>
}

export default BlogPage

export const pageQuery = graphql`
  {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/content/posts/"}}
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`